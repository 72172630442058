import React, { createContext, useState } from "react";
// import quizzes.json

import quizTypes from './quizzes.json';  


export const Context = createContext();

const randomNum= Math.floor(Math.random() * quizTypes.length)

const Provider = (props) => {
  const [quiz, setQuiz] = useState(quizTypes[randomNum]);

  return (
    <Context.Provider value={{ quiz, setQuiz }}>
      {props.children}
    </Context.Provider>
  );
};

export default Provider;
