import Quiz from "react-quiz-component";
import { quiz } from "./quiz";
import "./quiz.css";
import { Link } from "react-router-dom";
import { Context } from "./provider";

import { Button } from "@chakra-ui/react";

import React, { useState, useEffect, useContext } from "react";

function QuizStart(props) {
  // const [context, setContext] = useContext(Context);

  const { quiz, setQuiz } = useContext(Context);

  console.log("now printing activeQuiz");
  console.log(quiz);
  console.log("now printing activeQuiz");

  const Tquiz = {
    quizTitle: "NFT Quiz",
    quizSynopsis: "NFT Quiz. Test your jpeg knowledge ",
    questions: [
      {
        question: "What year was CryptoPunks Launched?",
        questionType: "text",
        answerSelectionType: "single",
        answers: ["2015", "2016", "2017", "2018"],
        correctAnswer: "3",
        messageForCorrectAnswer: "Correct answer. Good job.",
        messageForIncorrectAnswer: "Incorrect answer. Please try again.",
        point: "20",
      },
      {
        question:
          "Which is the most expensive NFT sold so far( as of October 2021)?",
        questionType: "text",
        answerSelectionType: "single",
        answers: [
          "Snowden NFT",
          "Everydays - The First 5000 days by Beeple",
          "Cryptopunk #7523",
        ],
        correctAnswer: "2",
        messageForCorrectAnswer: "Correct answer. Good job.",
        messageForIncorrectAnswer: "Incorrect answer. Please try again.",
        point: "20",
      },
      {
        question: "What Blockchain was Rare Pepe NFTs launched on?",
        questionType: "text",
        answerSelectionType: "single",
        answers: [
          "Counterparty platform on top of Bitcoin",
          "Ethereum Classic",
          "Litecoin",
          "Cardano",
        ],
        correctAnswer: "1",
        messageForCorrectAnswer: "Correct answer. Good job.",
        messageForIncorrectAnswer: "Incorrect answer. Please try again.",
        point: "10",
      },
      {
        question: "When was the ERC-721 standard first proposed?",
        questionType: "text",
        answerSelectionType: "single",
        answers: ["2015", "2016", "2018", "2017"],
        correctAnswer: "4",
        messageForCorrectAnswer: "Correct answer. Good job.",
        messageForIncorrectAnswer: "Incorrect answer. Please try again.",
        point: "30",
      },
      {
        question: "What ERC standard is used for NFT with multiple editions? ",
        questionType: "text",
        answerSelectionType: "single",
        answers: ["ERC-20", "ERC-1155", "ERC-898", "ERC-246"],
        correctAnswer: "2",
        messageForCorrectAnswer: "Correct answer. Good job.",
        messageForIncorrectAnswer: "Incorrect answer. Please try again.",
        explanation:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        point: "20",
      },
    ],
  };

  for (let i = 0; i < quiz.length; i++) {
    const element = quiz[i];
    console.log(element);
  }

  const setQuizResult = (obj) => {
    console.log(obj);
    const timeInMs = Date.now();

    const totalPoints = obj.totalPoints.toString();
    const correctPoints = obj.correctPoints.toString();

    const data = {
      time: timeInMs,
      points: correctPoints,
      totalQuestions: totalPoints,
      quiz: quiz.quizTitle,
    };

    console.log(timeInMs);

    console.log(data);

    fetch(
      "https://d1ch2ykvtg.execute-api.us-east-1.amazonaws.com/test/message",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "rbqoWEr4uz5zMd5Sh3W0Gh4XjL81hoB5BPsjjTv4",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => console.log("Success:", data))
      .catch((error) => console.error("Error:", error));
  };

  return (
    // add margin of 20px
    <div style={{ margin: "100px" }}>
      <div className="outerQuiz">
        <Quiz
          style={{
            alignItems: "center",
            border: "20px solid lightgray",
            justifyContent: "center",
            borderBottom: "solid 1px",
            paddingBottom: "1rem",
            display: "flex",
          }}
          quiz={quiz}
          onComplete={setQuizResult}
        />
      </div>

      <div
        style={{
          margin: "20%",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* after the quiz is complete, store the score in a database */}

        <Link to="/">
          <Button variantColor="teal" size="md">
            Go back to home
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default QuizStart;
