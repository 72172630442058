
const questions = 
[
    {
        "question": "Which movie franchise do you prefer, 'Chakka Panja' or 'Kabaddi'?",
        "option_1": "Chakka Panja",
        "option_2": "Kabaddi"
    },
    {
        "question": "Who do you think is a more effective leader, Balen Shah or Rabi Lamichhane?",
        "option_1": "Balen Shah",
        "option_2": "Rabi Lamichhane"
    },
    {
        "question": "Who do you like more, KP Oli or Prachanda?",
        "option_1": "KP Oli",
        "option_2": "Prachanda"
    },
    {
        "question": "Which actor do you enjoy watching more, Bipin Karki or Anmol KC?",
        "option_1": "Bipin Karki",
        "option_2": "Anmol KC"
    },
    {
        "question": "Which band do you prefer, 1974 AD or Nepathya?",
        "option_1": "1974 AD",
        "option_2": "Nepathya"
    },
    {
        "question": "Which food delivery app do you use more, Foodmandu or Pathao?",
        "option_1": "Foodmandu",
        "option_2": "Pathao"
    },
    {
        "question": "What's your preferred online shopping platform, Daraz or HamroBazar?",
        "option_1": "Daraz",
        "option_2": "HamroBazar"
    },
    {
        "question": "Which Nepali mobile app do you find more useful, Khalti or eSewa?",
        "option_1": "Khalti",
        "option_2": "eSewa"
    },
    {
        "question": "Which company would you prefer to work for, Ncell or Nepal Telecom?",
        "option_1": "Ncell",
        "option_2": "Nepal Telecom"
    },
    {
        "question": "Who do you think was a more influential Prime Minister, BP Koirala or Man Mohan Adhikari?",
        "option_1": "BP Koirala",
        "option_2": "Man Mohan Adhikari"
    },
    {
        "question": "Where would you rather go for a dream vacation, Mustang or New York?",
        "option_1": "Mustang",
        "option_2": "New York"
    },
    {
        "question": "Who's your preferred Nephop artist, Yama Buddha or VTEN?",
        "option_1": "Yama Buddha",
        "option_2": "VTEN"
    },
    {
        "question": "Which coffee shop in Kathmandu do you prefer, Himalayan Java or Cafe Soma?",
        "option_1": "Himalayan Java",
        "option_2": "Cafe Soma"
    },
    {
        "question": "Which country would you rather visit, Australia or USA?",
        "option_1": "Australia",
        "option_2": "USA"
    },
    {
        "question": "What is something your generation does that you actually dislike?",
        "option_1": "Excessive social media use",
        "option_2": "Cancel culture"
    },
    {
        "question": "What's your favorite Nepali food?",
        "option_1": "Momo",
        "option_2": "Dal Bhat"
    },
    {
        "question": "Who's your favorite Nepali TikToker, Bhimphedi Guys or Sisan Baniya?",
        "option_1": "Bhimphedi Guys",
        "option_2": "Sisan Baniya"
    },
    {
        "question": "Who's a more popular news anchor/TV host, Rabi Lamichhane or Bhusan Dahal?",
        "option_1": "Rabi Lamichhane",
        "option_2": "Bhusan Dahal"
    },
    {
        "question": "Who do you think should be the next Prime Minister of Nepal?",
        "option_1": "Gagan Thapa",
        "option_2": "Rabindra Mishra"
    },
    {
        "question": "Which tourist location in Nepal do you consider a hidden gem, Rara Lake or Dolpo?",
        "option_1": "Rara Lake",
        "option_2": "Dolpo"
    },
    {
        "question": "What's your favorite Nepali reality show?",
        "option_1": "Nepal Idol",
        "option_2": "The Voice of Nepal"
    },
    {
        "question": "Which cartoon did you grow up watching, 'Meena Cartoon' or 'Moomin'?",
        "option_1": "Meena Cartoon",
        "option_2": "Moomin"
    },
    {
        "question": "Which do you prefer, old Nepali films or new Nepali films?",
        "option_1": "Old Nepali films",
        "option_2": "New Nepali films"
    },
    {
        "question": "Which food do you enjoy more, Momo or Sukuti?",
        "option_1": "Momo",
        "option_2": "Sukuti"
    },
    {
        "question": "Who is your favorite Nepali cricketer?",
        "option_1": "Paras Khadka",
        "option_2": "Sandeep Lamichhane"
    },
    {
        "question": "What's your favorite Nepali movie?",
        "option_1": "Loot",
        "option_2": "Kabaddi"
    },
    {
        "question": "Who's your favorite living Nepali singer, Nabin K Bhattarai or Raju Lama?",
        "option_1": "Nabin K Bhattarai",
        "option_2": "Raju Lama"
    },
    {
        "question": "Which is your favorite Nepali festival, Dashain or Tihar?",
        "option_1": "Dashain",
        "option_2": "Tihar"
    },
    {
        "question": "Which is more scenic, Pokhara or Ilam?",
        "option_1": "Pokhara",
        "option_2": "Ilam"
    },
    {
        "question": "Which traditional Nepali instrument do you enjoy listening to, Madal or Sarangi?",
        "option_1": "Madal",
        "option_2": "Sarangi"
    },
    {
        "question": "What's your favorite Nepali television show?",
        "option_1": "Harke Haldar",
        "option_2": "Bhadragol"
    },
    {
        "question": "Which trekking destination do you prefer, Annapurna Circuit or Everest Base Camp?",
        "option_1": "Annapurna Circuit",
        "option_2": "Everest Base Camp"
    },
    {
        "question": "Which Nepali temple do you prefer visiting, Pashupatinath or Swayambhunath?",
        "option_1": "Pashupatinath",
        "option_2": "Swayambhunath"
    },
    {
        "question": "Which is a better weekend getaway, Nagarkot or Bandipur?",
        "option_1": "Nagarkot",
        "option_2": "Bandipur"
    },
    {
        "question": "What's your favorite Nepali snack?",
        "option_1": "Sel Roti",
        "option_2": "Chatamari"
    },
    {
        "question": "Who do you find more inspiring as a Nepali entrepreneur, Binod Chaudhary or Mahabir Pun?",
        "option_1": "Binod Chaudhary",
        "option_2": "Mahabir Pun"
    },
    {
        "question": "Which local market do you prefer, Ason Bazaar or Thamel?",
        "option_1": "Ason Bazaar",
        "option_2": "Thamel"
    },
    {
        "question": "Which region of Nepal do you prefer, Terai or Hills?",
        "option_1": "Terai",
        "option_2": "Hills"
    },
    {
        "question": "Which mode of local transport do you find more convenient in Kathmandu, Microbus or Safa Tempo?",
        "option_1": "Microbus",
        "option_2": "Safa Tempo"
    },
    {
        "question": "What's your favorite traditional Nepali game?",
        "option_1": "Dandi Biyo",
        "option_2": "Kabbadi"
    },
    {
        "question": "Which electric vehicle brand do you prefer, BYD or Hyundai?",
        "option_1": "BYD",
        "option_2": "Hyundai"
    },
    {
        "question": "Which is more important for Nepal’s development, the Upper Arun Hydroelectric Project or the growth of the EV market?",
        "option_1": "Upper Arun Hydroelectric Project",
        "option_2": "Growth of the EV market"
    },
    {
        "question": "Which social media platform do you use more, Facebook or Instagram?",
        "option_1": "Facebook",
        "option_2": "Instagram"
    },
    {
        "question": "Which internet service provider do you prefer, CG Net or WorldLink?",
        "option_1": "CG Net",
        "option_2": "WorldLink"
    },
    {
       

 "question": "Which would you choose, a guaranteed job for life or 5 Lakh Rupees to start your own business?",
        "option_1": "Guaranteed job for life",
        "option_2": "5 Lakh Rupees"
    },
    {
        "question": "Which would you prefer, free healthcare for life or 25 Lakh Rupees cash?",
        "option_1": "Free healthcare for life",
        "option_2": "25 Lakh Rupees cash"
    },
    {
        "question": "Would you work night shifts for a year if it meant doubling your salary?",
        "option_1": "Yes",
        "option_2": "No"
    },
    {
        "question": "Which would you choose, a fully funded master's degree abroad or a new car and 10 Lakh Rupees?",
        "option_1": "Fully funded master's degree abroad",
        "option_2": "New car and 10 Lakh Rupees"
    },
    {
        "question": "Would you give up meat and become vegetarian for 5 Lakh Rupees?",
        "option_1": "Yes",
        "option_2": "No"
    },
    {
        "question": "Which would you prefer, a stable job in Kathmandu or a higher-paying job in a village?",
        "option_1": "Stable job in Kathmandu",
        "option_2": "Higher-paying job in a village"
    },
    {
        "question": "Would you learn a new language if it guaranteed a job abroad?",
        "option_1": "Yes",
        "option_2": "No"
    },
    {
        "question": "Which would you choose, free flat rent in Kathmandu for 5 years or 10 Lakh Rupees cash?",
        "option_1": "Free flat rent in Kathmandu",
        "option_2": "10 Lakh Rupees cash"
    },
    {
        "question": "Which would you give up for 1 year, social media or eating momo?",
        "option_1": "Social media",
        "option_2": "Eating momo"
    },
    {
        "question": "Which would you prefer, free momos for life but only on Saturdays, or free dal bhat for life but only for lunch?",
        "option_1": "Free momos for life",
        "option_2": "Free dal bhat for life"
    },
    {
        "question": "What would you rather do, eat only vegetarian momos for a year or eat only chicken dal bhat for a year?",
        "option_1": "Eat only vegetarian momos",
        "option_2": "Eat only chicken dal bhat"
    },
    {
        "question": "Which challenge would you take, no social media for 6 months or no momos for 1 year?",
        "option_1": "No social media for 6 months",
        "option_2": "No momos for 1 year"
    },
    {
        "question": "Which is a better spot for a peaceful retreat, Lumbini or Chitwan?",
        "option_1": "Lumbini",
        "option_2": "Chitwan"
    },
    {
        "question": "Which city do you think has better street food, Kathmandu or Pokhara?",
        "option_1": "Kathmandu",
        "option_2": "Pokhara"
    },
    {
        "question": "Which social issue should be prioritized in Nepal, education or healthcare?",
        "option_1": "Education",
        "option_2": "Healthcare"
    },
    {
        "question": "Which do you prefer, trekking in the Himalayas or exploring Terai wildlife?",
        "option_1": "Trekking in the Himalayas",
        "option_2": "Exploring Terai wildlife"
    },
    {
        "question": "Which is more important for you, a good education system or a strong healthcare system?",
        "option_1": "Good education system",
        "option_2": "Strong healthcare system"
    },
    {
        "question": "Which genre of Nepali music do you enjoy more, folk or pop?",
        "option_1": "Folk",
        "option_2": "Pop"
    },
    {
        "question": "Which type of Nepali dance do you find more exciting, traditional or contemporary?",
        "option_1": "Traditional",
        "option_2": "Contemporary"
    },
    {
        "question": "Which adventure sport would you try, paragliding in Pokhara or rafting in the Trishuli River?",
        "option_1": "Paragliding in Pokhara",
        "option_2": "Rafting in the Trishuli River"
    }
];

export default questions;
