import * as React from 'react'
import { BrowserRouter,Link } from "react-router-dom";

// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'
import { Box, Text, VStack, Code, Grid,Flex, Spacer, Image, Badge } from "@chakra-ui/react"
import { StarIcon } from "@chakra-ui/icons"
import techImage from "./images/nft-technical.png"
import { MyContext } from './provider';
import { useContext, useState } from 'react';

import { Context } from './provider';
// Sample card from Airbnb

export function AirbnbExample(props) {

  const { quiz, setQuiz } = useContext(Context);

  const handleClick = (selectedQuiz) => {
    setQuiz(selectedQuiz);
    console.log("now printing quiz")
    
  };



    const property = {
      imageUrl: props.imageUrl,
      imageAlt: 'quiz',
      questions: props.questions,
      points: props.questions * 10,
      title: props.title,
      formattedPrice: props.type,
      reviewCount: Math.floor(Math.floor(Math.random() * 1000) + 1),
      rating: Math.floor(Math.floor(Math.random() * 5) + 1),
      quiz: props.quiz
    }

    var images= [techImage, "https://bit.ly/2Z4KKcF", "https://media.istockphoto.com/photos/luxurious-beautiful-modern-villa-with-front-yard-garden-at-sunset-picture-id1283532082?b=1&k=20&m=1283532082&s=170667a&w=0&h=KxQ3cfMs-Xi7FL2TXfrgFbi9pwtBOdjSEc4-ufAeVlo="]
    
    return (
      
      
      <Box  maxW='sm' boxShadow='dark-lg' borderWidth='5px' m='10px' borderRadius='lg' overflow='hidden'>
        <Link to="/quiz"> <Image width="360" height="360" src={property.imageUrl} alt={property.imageAlt}  onClick={() => handleClick(property.quiz)}/> </Link>
  
        <Box p='6' mt='10'>
          <Box display='flex' alignItems='baseline'>
        
            <Box
            m='5'
              color='gray.500'
              fontWeight='semibold'
              letterSpacing='wide'
              fontSize='xs'
              textTransform='uppercase'
             
            >
              {property.questions} questions &bull; {property.points} points
            </Box>
          </Box>
  
          <Box
            mt='5'
            mb='5'
            fontWeight='semibold'
            as='h3'
            lineHeight='tight'
            isTruncated
          >
            {property.title}
          </Box>
  
          <Box mb='5'>
            {property.formattedPrice}
            
          </Box>
  
       
        </Box>
      </Box>
 
    )
  }

// https://9opesuegjh.execute-api.us-east-1.amazonaws.com/test/


