import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

export default function DataTable(props) {
  const { data, ...other } = props;

  const columns = [
    { field: 'time', headerName: 'Time', width: 100, flex: 1 },
    { field: 'question', headerName: 'Poll Question', width: 200, flex: 2 },
    { field: 'comment', headerName: 'Poll Response', width: 100, flex: 1 }
  ];

  data.forEach((item, i) => {
    item.id = i + 1;
  });

  return (
    <div style={{ height: 400, width: '100%', maxWidth: '100vw' }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        autoHeight
        disableExtendRowFullWidth
        disableColumnMenu
        {...other}
      />
    </div>
  );
}