import React, { useState, useEffect } from "react";
import axios from "axios";
import { Rings } from "react-loader-spinner";
import { AirbnbExample } from "./card.js";
import DataTable from "./dataTable";
import Poll from "./poll";
import quizTypes from "./quizzes.json";
import { Context } from "./context.js";
import usePageTracking from "./usePageTracking";
import logo from "./images/logo1.png";
import CategoryPoll from "./CategoryPoll.js";

function Home() {
  usePageTracking();

  const [context, setContext] = useState(quizTypes[0]);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`https://9opesuegjh.execute-api.us-east-1.amazonaws.com/test/`)
      .then((res) => {
        const items = res.data;
        items.forEach((item) => {
          item.time = new Date(item.time).toDateString();
        });
        setData(items);
      });
  }, []);

  return (
    <Context.Provider value={[context, setContext]}>
      <div
        className="HomeContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "0 20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "150px",
            backgroundColor: "white",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ maxWidth: "250px", height: "auto", maxHeight: "150px" }}
          />
        </div>

        {!data && (
          <Rings heigth="200" width="200" color="grey" ariaLabel="loading" />
        )}

        <CategoryPoll />

        <h1
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            width: "100%",
            margin: "10px 0",
            padding: "0",
          }}
        >
          Quizzes
        </h1>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "stretch",
            width: "100%",
            gap: "20px",
          }}
        >
          {quizTypes.map((item) => (
            <div
              key={item.quizTitle}
              style={{
                flex: "1 1 300px",
                maxWidth: "350px",
                margin: "10px",
                border: "1px solid lightgray",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <AirbnbExample
                shadow="base"
                _hover={{
                  color: "green.500",
                  shadow: "2xl",
                }}
                type={item.quizSynopsis}
                title={item.quizTitle}
                questions={item.questions.length}
                imageUrl={item.imageUrl}
                quiz={item}
                onClick={() => setContext(item)}
              />
            </div>
          ))}
        </div>

        <div style={{ width: "100%" }}>
          <Poll />
        </div>

        <h2
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            width: "100%",
            margin: "25px 0",
          }}
        >
          Poll Comments
        </h2>

        <div
          style={{
            width: '100%',
            overflowX: 'auto',
          }}
        >
          <DataTable data={data} />
        </div>
      </div>
    </Context.Provider>
  );
}

export default Home;