import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Confetti from 'react-confetti';
import { motion } from 'framer-motion';
import categories from './battle-questions';

const CategoryPoll = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [remainingOptions, setRemainingOptions] = useState([]);
  const [winner, setWinner] = useState(null);

  useEffect(() => {
    if (selectedCategory) {
      const options = [...categories[selectedCategory].options];
      shuffleArray(options);
      setRemainingOptions(options.slice(2));
      setCurrentOptions([options[0], options[1]]);
    }
  }, [selectedCategory]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const handleChoice = (chosenOption) => {
    const notChosenOption = currentOptions.find(option => option !== chosenOption);
    const timeInMs = new Date().getTime();
    const currentQuestion = categories[selectedCategory];
    const formattedQuestion = `${currentQuestion.question} ${currentOptions[0]} or ${currentOptions[1]}?`;
    const data = {
      question: formattedQuestion,
      message: chosenOption,
      time: timeInMs,
      otherOption: notChosenOption
    };

    fetch("https://aab6tr8987.execute-api.us-east-1.amazonaws.com/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then((res) => res.json())
    .then((data) => {
      console.log("Response submitted successfully");
      if (remainingOptions.length > 0) {
        setCurrentOptions([chosenOption, remainingOptions[0]]);
        setRemainingOptions(remainingOptions.slice(1));
      } else {
        setWinner(chosenOption);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
  };

  const resetPoll = () => {
    setSelectedCategory(null);
    setCurrentOptions([]);
    setWinner(null);
    setRemainingOptions([]);
  };

  return (
            <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>

      <Typography variant="h3" align="center" gutterBottom style={{ fontWeight: 'bold', color: '#333' }}>
        YO ki TYO: 
      </Typography>
      {!selectedCategory ? (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))', gap: '20px', marginBottom: '40px' }}>
          {Object.keys(categories).map((category) => (
            <Card key={category} onClick={() => setSelectedCategory(category)} style={{ cursor: 'pointer', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <CardHeader 
                title={category} 
                style={{ textAlign: 'center', fontWeight: 'bold', color: '#555' }} 
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary" paragraph style={{ textAlign: 'center', fontSize: '1.2rem', color: '#777' }}>
                  {categories[category].question}
                </Typography>
                <Button variant="contained" fullWidth style={{ backgroundColor: '#1976d2', color: 'white' }}>Start the Showdown!</Button>
              </CardContent>
            </Card>
          ))}
        </div>
      ) : winner ? (
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={false}
            numberOfPieces={200}
          />
          <Card elevation={6} style={{ padding: '20px', marginTop: '20px', textAlign: 'center' }}>
            <CardContent>
              <Typography variant="h5" paragraph>
                Your top pick for
              </Typography>
              <Typography variant="h6" paragraph style={{ fontStyle: 'italic', color: '#444', marginBottom: '20px' }}>
                "{categories[selectedCategory].question}"
              </Typography>
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.5 }}
              >
                <Typography variant="h3" paragraph style={{ 
                  color: '#1976d2',
                  fontWeight: 'bold',
                  textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
                  margin: '30px 0'
                }}>
                 is... {winner}
                </Typography>
              </motion.div>
              
              <Button 
                variant="contained" 
                onClick={resetPoll} 
                style={{ 
                  display: 'block', 
                  margin: '20px auto',
                  padding: '10px 20px',
                  fontSize: '1.1rem'
                }}
              >
                Start a New Poll
              </Button>
            </CardContent>
          </Card>
        </motion.div>
      ) : (
        <div style={{ marginTop: '40px' }}>
          <Typography variant="h5" align="center" gutterBottom style={{ fontWeight: 'bold' }}>
            {selectedCategory} Face-off!
          </Typography>
          <Card 
            elevation={6} 
            style={{ 
              padding: '20px', 
              marginBottom: '40px', 
              backgroundColor: '#f5f5f5', 
              boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)'
            }}
          >
            <Typography 
              variant="h4" 
              align="center" 
              gutterBottom 
              style={{ 
                color: '#004766', 
                fontSize: '1.8rem', 
                fontWeight: 'bold',
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)'
              }}
            >
              {categories[selectedCategory].question}
            </Typography>
          </Card>
          <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
            {currentOptions.map((option, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{ flex: 1, margin: '0 20px' }}
              >
                <Card 
                  onClick={() => handleChoice(option)}
                  style={{ 
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    transition: 'background-color 0.3s, box-shadow 0.3s',
                    minWidth: '300px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <CardContent style={{ height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h5" component="div" style={{ fontWeight: 'bold' }}>
                      {option}
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
          <Typography variant="body2" align="center" style={{ marginTop: '20px', color: '#777' }}>
            Remaining options: {remainingOptions.length + 1}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default CategoryPoll;