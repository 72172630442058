import './App.css';
import React from 'react';
import { AirbnbExample } from './card.js';
import quizTypes from './quizzes.json';
import usePageTracking from './usePageTracking';
import logo from './images/logo1.png';
import CategoryPoll from './CategoryPoll';

const QuizPage = () => {
  console.log('QuizPage component rendering'); // Debug log

  usePageTracking();

  const handleQuizClick = (quiz) => {
    // Here you can add logic to start the quiz or navigate to a specific quiz page
    console.log('Starting quiz:', quiz.quizTitle);
    // For example, you might use React Router to navigate:
    // history.push(`/quiz/${quiz.id}`);
  };

  return (
    <div className="HomeContainer" style={{ display: 'flex', flexDirection: 'column' }}>
    


      <h1 style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', margin: '10px 0', padding: '0' }}>
        Quizzes
      </h1>

      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        {quizTypes.map((item) => (
          <div key={item.id} style={{ margin: '25px', flexWrap: 'nowrap', border: '20px solid lightgray' }}>
            <AirbnbExample
              shadow="base"
              _hover={{ color: 'green.500', shadow: '2xl' }}
              type={item.quizSynopsis}
              title={item.quizTitle}
              questions={item.questions.length}
              imageUrl={item.imageUrl}
              quiz={item}
              onClick={() => handleQuizClick(item)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuizPage;