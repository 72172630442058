const categories = {
    'Prime Minister': {
      question: 'Who would you rather have as a PM?',
      options: [
        'Sher Bahadur Deuba', 'KP Sharma Oli', 'Pushpa Kamal Dahal', 'Baburam Bhattarai',
        'Madhav Kumar Nepal', 'Gagan Thapa', 'Balen Shah', 'Rabi Lamichhane',
        'Bidhya Devi Bhandari', 'Ram Chandra Poudel'
      ]
    },
    'Nepali Food': {
      question: 'Which Nepali dish do you prefer?',
      options: [
        'Momos', 'Dal Bhat', 'Sel Roti', 'Yomari', 'Gundruk', 'Dhido',
        'Chatamari', 'Bara', 'Kwati', 'Thukpa'
      ]
    },
    'Nepali Landmarks': {
      question: 'Which Nepali landmark would you rather visit?',
      options: [
        'Swayambhunath', 'Boudhanath', 'Pashupatinath', 'Kathmandu Durbar Square',
        'Poon Hill', 'Annapurna Base Camp', 'Lumbini', 'Janaki Temple',
        'Tilicho Lake', 'Phewa Lake'
      ]
    },
    'Nepali Movies': {
      question: 'Which Nepali movie is your favorite?',
      options: [
        'Kagbeni', 'Loot', 'Pashupati Prasad', 'Jhola', 'Chhakka Panja',
        'Kabaddi', 'Talakjung vs Tulke', 'Hostel', 'Darpan Chhaya', 'Sano Sansar'
      ]
    },
    'Nepali Music': {
      question: 'Which Nepali song do you enjoy the most?',
      options: [
        'Resham Firiri', 'Syndicate', 'Sarangi', 'Suna Saili', 'Sa Karnali',
        'Laija Re',  'Kutu Ma Kutu', 'Yo Maan Ta Mero Nepali Ho'
      ]
    },
    'Live Abroad': {
      question: 'Which country would you prefer to live in abroad?',
      options: [
        'USA', 'Australia', 'UK', 'Canada', 'Japan', 'Germany',
        'South Korea', 'New Zealand', 'Switzerland', 'Norway'
      ]
    },
    'Nepali Festivals': {
      question: 'Which Nepali festival is your favorite?',
      options: [
        'Dashain', 'Tihar', 'Teej', 'Holi', 'Indra Jatra', 'Chhath',
        'Maghe Sankranti', 'Buddha Jayanti', 'Losar', 'Gai Jatra'
      ]
    },
    'Nepali Sports': {
      question: 'Which sport do you enjoy watching the most?',
      options: [
        'Football', 'Cricket', 'Basketball', 'Volleyball', 'Badminton',
        'Table Tennis', 'Boxing', 'Wrestling', 'Athletics', 'Swimming'
      ]
    },
    'Bike Brands': {
      question: 'Which bike brand do you prefer?',
      options: [
        'Pulsar', 'KTM', 'Royal Enfield Bullet', 'Yamaha R1', 'Harley-Davidson',
        'Honda', 'Suzuki', 'TVS', 'Bajaj', 'Hero'
      ]
    },
    'Car Brands': {
      question: 'Which car brand do you prefer?',
      options: [
        'Toyota', 'Hyundai', 'Suzuki', 'Honda', 'Ford',
        'Mahindra', 'Tata', 'Kia', 'Volkswagen', 'Nissan'
      ]
    },
    'Gadgets': {
      question: 'Which gadget do you use the most?',
      options: [
        'Smartphone', 'Laptop', 'Tablet', 'Smartwatch', 'Camera',
        'Gaming Console', 'Headphones', 'Smart Speaker', 'E-reader', 'Fitness Tracker'
      ]
    },
    'Consumer Goods': {
      question: 'Which consumer good do you value the most?',
      options: [
        'Smartphones', 'Laptops', 'Televisions', 'Home Appliances', 'Fashion Accessories',
        'Footwear', 'Cosmetics', 'Fitness Equipment', 'Books', 'Watches'
      ]
    }
  };
  
  export default categories;
  