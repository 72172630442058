import React, { useState, useEffect } from "react";
import questions from "./poll-questions";

const SimplePoll = () => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [response, setResponse] = useState("");

  useEffect(() => {
    setQuestionIndex(Math.floor(Math.random() * questions.length));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selectedOption) {
      setResponse("Please select an option before submitting.");
      return;
    }

    const timeInMs = new Date().getTime();
    const currentQuestion = questions[questionIndex];
    const data = {
      question: currentQuestion.question,
      message: selectedOption,
      time: timeInMs,
    };

    fetch("https://aab6tr8987.execute-api.us-east-1.amazonaws.com/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then((res) => res.json())
    .then((data) => {
      setResponse("Response submitted! Here's another question.");
      setQuestionIndex((prevIndex) => (prevIndex + 1) % questions.length);
      setSelectedOption("");
    })
    .catch((error) => {
      setResponse("Error submitting response. Please try again.");
      console.error("Error:", error);
    });
  };

  const currentQuestion = questions[questionIndex];

  if (!currentQuestion) {
    return <div>No questions available</div>;
  }

  return (
    <div style={{
      maxWidth: '500px',
      margin: '0 auto',
      padding: '20px',
      backgroundColor: '#f0f0f0',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    }}>
      <h2 style={{ marginBottom: '20px' }}>Poll: {currentQuestion.question}</h2>
      <form onSubmit={handleSubmit}>
        {Object.entries(currentQuestion)
          .filter(([key]) => key.startsWith('option_'))
          .map(([key, value]) => (
            <div key={key} style={{ marginBottom: '10px' }}>
              <input
                type="radio"
                id={key}
                name="poll-option"
                value={value}
                checked={selectedOption === value}
                onChange={(e) => setSelectedOption(e.target.value)}
                style={{ marginRight: '10px' }}
              />
              <label htmlFor={key}>{value}</label>
            </div>
          ))}
        <button 
          type="submit" 
          disabled={!selectedOption}
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Submit
        </button>
      </form>
      {response && <p style={{ marginTop: '20px', textAlign: 'center' }}>{response}</p>}
    </div>
  );
};

export default SimplePoll;